body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  width: 100%;
  color: #424242;
}


* {
  box-sizing: border-box;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-top: 70px;
  padding-left: 70px;
  background-color: #010618;
  font-family: 'Open Sans', sans-serif;
  color: #CED9FF;
}

.App > * {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #003B3D;
}

.App-content {
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 20px;
  width: calc(75% - 120px);
}

@media screen and (max-width:700px) {
  .App {
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
    display: block;
    padding-top: 0;
    padding-left: 30px;
  }

  .App-content {
    width: calc(100% - 15px);
    margin: 0;
    margin-top: 20px;
    overflow: visible;
  }
}

.Post-container {
  margin: 15px;
  position: relative;
}

.Post-title {
  color: #5D71B5;
}

.Post-title:hover {
  color: inherit;
}

.Post-date {
  font-size: 11px;
  position: absolute;
  top: 10px;
  right: 0;
}

.App-header {
  width: 25%;
  min-width: 250px;
  height: 100%;
}

.App-header-photo {
  width: calc(100% - 30px);
  max-width: 150px;
  margin: auto;
  border: 1px solid #010618;
  border-radius: 50%;
  -webkit-filter: url('#gray');
          filter: url('#gray');
}

.App-header-photo:hover {
  -webkit-filter: url('#purple');
          filter: url('#purple');
}

.App-photo-filter {
  width: 0;
  height: 0;
}

.App-header-name {
  font-weight: bold;
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

.App-header-name:hover {
  color: #003B3D;
}

.App-tagline {
  margin: 5px 0;
  font-size: 13px;
}

.Header-img-container {
  margin-top: 15px;
}

.Header-img {
  width: 16px;
  margin-right: 25px;
}

@media screen and (max-width:700px) {

  .App-header {
    width: 100%;
    max-height: 200px;
    border-right: none;
    padding-bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }


  .App-header-photo {
    max-width: 100px;
  }

  .App-header-text {
    width: calc(100% - 125px);
  }

}

.Border-line {
  position: fixed;
  opacity: 0.5;
}

.Border-line-vertical {
  height: 100%;
  width: 10px;
}

.Border-line-horizontal {
  width: 100%;
  height: 10px;
}

.Border-green {
  background-color: #003B3D;
  left: 30px;
  top: 0;
  z-index: 0;
}

.Border-green-small {
  height: 40px;
  z-index: 4;
}

.Border-red {
  background-color: #5C0327;
  z-index: 2;
  left: 40px;
  top: 0;
}

.Border-purple {
  background-color: #340929;
  opacity: 0.7;
  left: 0;
  top: 30px;
  z-index: 3;
}

.Border-brown {
  background-color: #34222D;
  opacity: 0.7;
  left: 0;
  top: 40px;
  z-index: 1;
}

@media screen and (max-width:700px) {
  .Border-green, .Border-green-small {
    left: 0;
  }

  .Border-red {
    left: 10px;
  }

  .Border-brown {
    top: 10px
  }

  .Border-purple {
    top: 0;
  }
}
