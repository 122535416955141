
.App {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-top: 70px;
  padding-left: 70px;
  background-color: #010618;
  font-family: 'Open Sans', sans-serif;
  color: #CED9FF;
}

.App > * {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #003B3D;
}

.App-content {
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 20px;
  width: calc(75% - 120px);
}

@media screen and (max-width:700px) {
  .App {
    flex-direction: column;
    overflow-y: auto;
    display: block;
    padding-top: 0;
    padding-left: 30px;
  }

  .App-content {
    width: calc(100% - 15px);
    margin: 0;
    margin-top: 20px;
    overflow: visible;
  }
}
