.Border-line {
  position: fixed;
  opacity: 0.5;
}

.Border-line-vertical {
  height: 100%;
  width: 10px;
}

.Border-line-horizontal {
  width: 100%;
  height: 10px;
}

.Border-green {
  background-color: #003B3D;
  left: 30px;
  top: 0;
  z-index: 0;
}

.Border-green-small {
  height: 40px;
  z-index: 4;
}

.Border-red {
  background-color: #5C0327;
  z-index: 2;
  left: 40px;
  top: 0;
}

.Border-purple {
  background-color: #340929;
  opacity: 0.7;
  left: 0;
  top: 30px;
  z-index: 3;
}

.Border-brown {
  background-color: #34222D;
  opacity: 0.7;
  left: 0;
  top: 40px;
  z-index: 1;
}

@media screen and (max-width:700px) {
  .Border-green, .Border-green-small {
    left: 0;
  }

  .Border-red {
    left: 10px;
  }

  .Border-brown {
    top: 10px
  }

  .Border-purple {
    top: 0;
  }
}