.App-header {
  width: 25%;
  min-width: 250px;
  height: 100%;
}

.App-header-photo {
  width: calc(100% - 30px);
  max-width: 150px;
  margin: auto;
  border: 1px solid #010618;
  border-radius: 50%;
  filter: url('#gray');
}

.App-header-photo:hover {
  filter: url('#purple');
}

.App-photo-filter {
  width: 0;
  height: 0;
}

.App-header-name {
  font-weight: bold;
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

.App-header-name:hover {
  color: #003B3D;
}

.App-tagline {
  margin: 5px 0;
  font-size: 13px;
}

.Header-img-container {
  margin-top: 15px;
}

.Header-img {
  width: 16px;
  margin-right: 25px;
}

@media screen and (max-width:700px) {

  .App-header {
    width: 100%;
    max-height: 200px;
    border-right: none;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }


  .App-header-photo {
    max-width: 100px;
  }

  .App-header-text {
    width: calc(100% - 125px);
  }

}
