.Post-container {
  margin: 15px;
  position: relative;
}

.Post-title {
  color: #5D71B5;
}

.Post-title:hover {
  color: inherit;
}

.Post-date {
  font-size: 11px;
  position: absolute;
  top: 10px;
  right: 0;
}
